import { ApiFirebaseClient } from '@rle-core/api-client/api-firebase-client.service';

import { UserRepository } from './user-repository';

export class UserFirebaseRepository implements UserRepository {
	public static readonly SET_TOKEN_CLAIMS_FUNCTION = 'setClaims';

	constructor(private fns: ApiFirebaseClient) {}

	public setTokenClaims(userId: string, accessToken: string): Promise<void> {
		return userId && accessToken
			? this.fns.call<{ userId: string; accessToken: string }, void>(
					UserFirebaseRepository.SET_TOKEN_CLAIMS_FUNCTION,
					{
						userId,
						accessToken
					}
				)
			: Promise.reject('No claims data provided');
	}
}
