import { Inject, Injectable } from '@angular/core';

import { USER_REPOSITORY, UserRepository } from './user-repository';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		@Inject(USER_REPOSITORY)
		private readonly repository: UserRepository
	) {}

	public setTokenClaims(userId: string, accessToken: string): Promise<void> {
		return this.repository.setTokenClaims(userId, accessToken);
	}
}
