import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@rle-core/guards/auth.guard';
import { LoginPage } from '@rle-pages/public/login/login.page';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'desktop'
	},
	{
		path: 'desktop',
		canMatch: [AuthGuard],
		loadChildren: () => import('@rle-pages/desktop/desktop.module').then(m => m.DesktopModule)
	},
	{
		path: 'catalogue',
		canMatch: [AuthGuard],
		loadChildren: () => import('@rle-pages/catalogue/catalogue.module').then(m => m.CatalogueModule)
	},
	{
		path: 'error',
		loadChildren: () => import('@rle-pages/public/error/error.page.module').then(m => m.ErrorPageModule)
	},
	{
		path: 'login',
		component: LoginPage
	},
	{
		path: '__/auth/',
		component: LoginPage
	},
	{
		path: '**',
		redirectTo: '/error/notfound'
	}
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), LoginPage],
	exports: [RouterModule]
})
export class AppRoutingModule {}
