import { Constants } from './version';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	version: Constants.version,
	production: false,
	nativeAppLinkHost: 'dev.portal.labs.rle.de',
	firebase: {
		apiKey: 'AIzaSyBZZZOkd_88IkNwm-b6RD78esaebt5C_xw',
		authDomain: 'rle-portal-dev.firebaseapp.com', //'rle-portal-dev.firebaseapp.com', //'signin.dev.portal.labs.rle.de',
		projectId: 'rle-portal-dev',
		storageBucket: 'rle-portal-dev.appspot.com',
		messagingSenderId: '864997389982',
		appId: '1:864997389982:web:cd9b53d96e1f5d5d53ad8f'
	},
	keycloak: {
		url: 'https://identity.dev.portal.labs.rle.de',
		realm: 'master',
		clientId: 'rleportal',
		clientSecret: 'fgPOKeBPrpsL0cmbUEOtihYeuXaI81ab',
		redirectUri: 'https://dev.portal.labs.rle.de'
	},
	featureToggles: {},
	// Enable client side logging
	clientLogEnabled: true,
	// Use local emulator for authentication (must be started before)
	useAuthEmulator: false,
	// Use local emulator for database (must be started before)
	useDBEmulator: false,
	// Use local emulator for storage (must be started before)
	useStorageEmulator: false,
	// Use local emulator for functions (must be started before)
	useFunctionsEmulator: false,
	// Use mock data instead of accessing the real database
	useMockData: false,
	// Override system settings to force the theme mode to 'light' or 'dark'
	forceThemeMode: null,
	// Add an extra top padding for the device status bar / notch
	forceStatusbarPadding: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
