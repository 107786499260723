import { Component } from '@angular/core';
import { AuthService } from '@rle-core/auth/auth.service';

@Component({
	standalone: true,
	selector: 'app-login-page',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss']
})
export class LoginPage {
	constructor(private authService: AuthService) {}

	public login(): void {
		this.authService.login();
	}
}
