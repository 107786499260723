import { inject, InjectionToken } from '@angular/core';
import { ApiFirebaseClient } from '@rle-core/api-client/api-firebase-client.service';
import { environment } from '@rle-environments/environment';

import { UserFirebaseRepository } from './user-firebase-repository';
import { UserMockRepository } from './user-mock-repository';

export const USER_REPOSITORY = new InjectionToken<UserRepository>('UserRepository', {
	providedIn: 'root',
	factory: () =>
		environment.useMockData ? new UserMockRepository() : new UserFirebaseRepository(inject(ApiFirebaseClient))
});

export interface UserRepository {
	setTokenClaims(userId: string, accessToken: string): Promise<void>;
}
