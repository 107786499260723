import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import {
	Functions,
	httpsCallable,
	HttpsCallableOptions
} from '@angular/fire/functions';

import { ApiClientModule } from './api-client.module';

enum ErrorCode {
	OK = 'ok',
	CANCELLED = 'cancelled',
	UNKNOWN = 'unknown',
	INVALID_ARGUMENT = 'invalid-argument',
	NOT_FOUND = 'not-found',
	ALREADY_EXISTS = 'already-exists',
	PERMISSION_DENIED = 'permission-denied',
	FAILED_PRECONDITION = 'failed-precondition',
	ABORTED = 'aborted',
	OUT_OF_RANGE = 'out-of-range',
	INTERNAL = 'internal',
	UNAVAILABLE = 'unavailable',
	UNAUTHENTICATED = 'unauthenticated'
}

@Injectable({
	providedIn: ApiClientModule
})
export class ApiFirebaseClient {
	public static readonly ErrorCode = ErrorCode;

	constructor(private functions: Functions) {}

	public call<D, R>(
		functionName: string,
		data?: D,
		options?: HttpsCallableOptions
	): Promise<R> {
		return httpsCallable<D, R>(
			this.functions,
			functionName,
			options
		)(data)
			.then(d => (d?.data ? this.convertTimestamps<R>(d.data) : d?.data))
			.catch(error => {
				const code = error?.code as string;
				error.code = code?.substring(code?.indexOf('/') + 1); // remove slash because firebase-admin add a prefix to errors
				throw error;
			});
	}

	private convertTimestamps<T>(data: T): T {
		if (!data) {
			return data;
		}

		if (Array.isArray(data)) {
			return data.map(i => this.convertTimestamps(i)) as unknown as T;
		}
		if (!(data instanceof Object)) {
			return data;
		}

		return Object.entries(data).reduce(
			(a: any, [k, v]) => (
				v instanceof Object && '_seconds' in v && '_nanoseconds' in v
					? (a[k] = this.toTimestamp(
							v as {
								_seconds: number;
								_nanoseconds: number;
							}
						))
					: (a[k] = this.convertTimestamps(v)),
				a
			),
			{}
		);
	}

	private toTimestamp(value: {
		_seconds: number;
		_nanoseconds: number;
	}): Timestamp {
		// eslint-disable-next-line no-underscore-dangle
		return new Timestamp(value._seconds, value._nanoseconds);
	}
}
